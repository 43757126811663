export default [
  {
    path: '/checklists',
    name: 'checklists',
    component: () => import('@/views/apps/checklists/list/ItensList.vue'),
    meta: {
      resource: 'checklists',
      action: 'listar',
    },
  },
  {
    path: '/projetos',
    name: 'projetos',
    component: () => import('@/views/apps/projetos/list/ItensList.vue'),
    meta: {
      resource: 'projetos',
      action: 'listar',
    },
  },
  {
    path: '/projetos/:id/checklists',
    name: 'projetos-checklists',
    component: () => import('@/views/apps/projetos/checklists_do_projeto/ItensList.vue'),
    meta: {
      resource: 'projetos',
      action: 'atualizar',
    },
  },
  {
    path: '/projetos/:id/checklists/novo',
    name: 'projetos-checklists-novo',
    component: () => import('@/views/apps/projetos/checklists_do_projeto/NovoChecklist.vue'),
    meta: {
      resource: 'projetos',
      action: 'atualizar',
    },
  },
  {
    path: '/projetos/:id/localizacao',
    name: 'projeto-localizacoes',
    component: () => import('@/views/apps/projetos/localizacoes_do_projeto/ItensList.vue'),
    meta: {
      resource: 'projetos',
      action: 'atualizar',
    },
  },
  // {
  //   path: '/times',
  //   name: 'times',
  //   component: () => import('@/views/apps/times/list/ItensList.vue'),
  //   meta: {
  //     resource: 'times',
  //     action: 'listar',
  //   },
  // },

  {
    path: '/times/edit/:id',
    name: 'times-edit',
    component: () => import('@/views/apps/times/edit/ItemEdit.vue'),
    meta: {
      resource: 'clientes',
      action: 'atualizar',
    },
  },
  {
    path: '/loading',
    name: 'loadingContent',
    component: () => import('@/views/apps/loading/LoandingContent.vue'),
    meta: {
      resource: 'clientes',
      action: 'listar',
    },
  },
]
