export default [
  {
    path: '/permissoes',
    name: 'permissões',
    component: () => import('@/views/apps/permissoes/list/ItensList.vue'),
    meta: {
      resource: 'permissões',
      action: 'listar',
    },
  },

  {
    path: '/perfis',
    name: 'perfis',
    component: () => import('@/views/apps/perfis/list/ItensList.vue'),
    meta: {
      resource: 'perfis',
      action: 'listar',
    },
  },
  {
    path: '/perfis/:name',
    name: 'editar-perfil',
    component: () => import('@/views/apps/perfis/edit/ItemEdit.vue'),
    meta: {
      resource: 'perfis',
      action: 'listar',
    },
  },

]
