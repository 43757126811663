import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn } from '@/auth/utils'
import adm from './routes/adm'
import apps from './routes/apps'
import chartsMaps from './routes/charts-maps'
import colab from './routes/colab'
import dashboard from './routes/dashboard'
import formsTable from './routes/forms-tables'
import gestor from './routes/gestor'
import pages from './routes/pages'
import sam from './routes/sam'
import uiElements from './routes/ui-elements/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'inicio' },
    },

    ...adm,
    ...sam,
    ...gestor,
    ...colab,
    ...pages,
    ...apps,
    ...dashboard,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,

    {
      path: '*',
      redirect: 'nao-encontrado',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'nao-autorizado' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()

    next(getHomeRouteForLoggedInUser(userData ? userData.roles : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
