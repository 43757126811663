export default [
  {
    path: '/inicio',
    name: 'inicio',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    meta:{
      action: 'listar',
      resource: 'padrão',
    }
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
]
