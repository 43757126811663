export default [
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersPerfil.vue'),
    meta: {
      resource: 'usuários',
      action: 'atualizar',
    },
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      resource: 'usuários',
      action: 'atualizar',
    },
  },
  {
    path: '/disciplinas',
    name: 'disciplinas',
    component: () => import('@/views/apps/disciplinas/list/ItensList.vue'),
    meta: {
      resource: 'disciplinas',
      action: 'menu',
    },
  },
  {
    path: '/tipo-arquivos',
    name: 'tipo-arquivos',
    component: () => import('@/views/apps/tipo-arquivos/list/ItensList.vue'),
    meta: {
      resource: 'formatos',
      action: 'menu',
    },
  },
  {
    path: '/projeto-formatos',
    name: 'projeto-formatos',
    component: () => import('@/views/apps/projeto-formatos/list/ItensList.vue'),
    meta: {
      resource: 'formatos',
      action: 'menu',
    },
  },
  {
    path: '/obras',
    name: 'obras',
    component: () => import('@/views/apps/obras/list/ItensList.vue'),
    meta: {
      resource: 'obras',
      action: 'menu',
    },
  },
  {
    path: '/obras/edit/:id',
    name: 'obras-edit',
    component: () => import('@/views/apps/obras/edit/ItemEdit.vue'),
    meta: {
      resource: 'obras',
      action: 'atualizar',
    },
  },
  {
    path: '/obras/registros/:id',
    name: 'obras-registros',
    component: () => import('@/views/apps/obras/listRegistrosObras/ItensList.vue'),
    meta: {
      resource: 'obras',
      action: 'atualizar',
    },
  },

  {
    path: '/registros',
    name: 'registros',
    component: () => import('@/views/apps/registros/list/ItensList.vue'),
    meta: {
      resource: 'registros',
      action: 'menu',
    },
  },
  {
    path: '/especificacoes',
    name: 'especificacoes',
    component: () => import('@/views/apps/especificacoes/list/ItensList.vue'),
    meta: {
      resource: 'especificacoes',
      action: 'menu',
    },
  },
  {
    path: '/obra/:idObra/registro/:idRegistro',
    name: 'registro-detalhes',
    component: () => import('@/views/apps/registros/view/ItemView.vue'),
    meta: {
      resource: 'obras',
      action: 'atualizar',
    },
  },
  {
    path: '/obra/:idObra/registro-gerar-detalhamento/:idRegistro',
    name: 'registro-gerar-detalhamento',
    component: () => import('@/views/apps/registros/view/ItemDetalhamento.vue'),
    meta: {
      resource: 'obras',
      action: 'atualizar',
    },
  },
  {
    path: '/subdisciplinas',
    name: 'subdisciplinas',
    component: () => import('@/views/apps/subdisciplinas/list/ItensList.vue'),
    meta: {
      resource: 'subdisciplinas',
      action: 'menu',
    },
  },
  {
    path: '/subdisciplinas/:id/grupo-favoritos',
    name: 'grupo-favoritos',
    component: () => import('@/views/apps/grupo-favoritos/list/ItensList.vue'),
    meta: {
      resource: 'subdisciplinas',
      action: 'atualizar',
    },
  },
  {
    path: '/subdisciplinas/grupo-favoritos/:id',
    name: 'grupo-favoritos-edicao',
    component: () => import('@/views/apps/grupo-favoritos/edit/ItemEdit.vue'),
    meta: {
      resource: 'subdisciplinas',
      action: 'atualizar',
    },
  },
  {
    path: '/tarefas',
    name: 'tarefas',
    component: () => import('@/views/apps/tarefas/list/ItensList.vue'),
    meta: {
      resource: 'tarefas',
      action: 'menu',
    },
  },
  {
    path: '/usuarios',
    name: 'usuários',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      resource: 'usuários',
      action: 'menu',
    },
  },
  {
    path: '/classes',
    name: 'classes',
    component: () => import('@/views/apps/classes/list/ItensList.vue'),
    meta: {
      resource: 'classes',
      action: 'menu',
    },

  },
  {
    path: '/empresas',
    name: 'empresas',
    component: () => import('@/views/apps/empresas/list/ItensList.vue'),
    meta: {
      resource: 'empresas',
      action: 'menu',
    },
  },
  {
    path: '/status',
    name: 'status',
    component: () => import('@/views/apps/status/list/ItensList.vue'),
    meta: {
      resource: 'status',
      action: 'menu',
    },
  },
]
