import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import VueMask from 'v-mask'
import money from 'v-money'

// eslint-disable-next-line import/no-extraneous-dependencies
import Pusher from 'pusher-js'
import App from './App.vue'
import router from './router'
import store from './store'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/clipboard'
import '@/libs/portal-vue'
import '@/libs/sweet-alerts'
import '@/libs/toastification'
import '@/libs/tour'
import '@/libs/vue-select'
import '@axios'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Vue.use(money)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.use(VueMask)
Vue.use(money, { precision: 4 })

Vue.config.productionTip = false

// Mapeamento de hosts para chaves Pusher
const hostToPusherKey = {
  192: 'dd5b001a23c6c34c2a92',
  localhost: 'dd5b001a23c6c34c2a92',
  hgl: 'dd5b001a23c6c34c2a92',
  default: 'f139336358ee9ba2a37e',
}

// Obtenha a chave Pusher com base no host
const { host } = window.location
let pusherKey = 'default'
for (const key in hostToPusherKey) {
  if (host.includes(key)) {
    pusherKey = key
    break
  }
}

// Crie o objeto Pusher
Vue.prototype.$pusher = new Pusher(hostToPusherKey[pusherKey], {
  cluster: 'sa1',
})

// Ative o log do console para Pusher se o host for '192', 'localhost' ou 'hgl'
if (['192', 'localhost', 'hgl'].some(key => host.includes(key))) {
  Pusher.logToConsole = true
}

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
